.nisha1 {
    position: relative;
    width: 100%;
  }
  
  .image1 {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle1 {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .nisha1:hover .image1 {
    opacity: 0.2;
  }
  
  .nisha1:hover .middle1 {
    opacity: 1;
  }
  
  .text {
   
    color: rgb(5, 13, 70);
    font-size: 16px;
    padding: 16px 32px;
  }