.nisha {
    position: relative;
    width: 100%;
  }
  
  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 69%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .nisha:hover .image {
    opacity: 0.7;
  }
  
  .nisha:hover .middle {
    opacity: 1;
  }
  
  .text {
 
    color: rgb(255, 255, 255);
    font-size: 16px;
    padding: 16px 32px;
  }